<template>
  <div v-if="isLoaded" class="home-wish">
    <div class="wish-bar" @click="handleSeeAllMyWishes">
      <h2 class="wish-title">Posted Wishes</h2>
      <div class="wish-bar-filler"></div>
      <div class="wish-bar-see-all">
        <h3>See All</h3>
        <div>
          <ion-icon class="small-icon" name="chevron-forward-outline"></ion-icon>
        </div>
      </div>
    </div>
    <div v-if="filteredWishesData.length == 0" class="default-wish-column">
      <div class="default-wish-column-container">
        <h3>You haven't posted any wishes yet.</h3>
        <div>Let alumni know what positions you are aiming for by posting them in the WishList.</div>
        <button @click="toWishlist">Go to WishList</button>
      </div>
    </div>

    <!-- <div v-if="filteredWishesData.length == 0 && isSearching" class="default-wish-column">
      <div class="default-wish-column-container">
        <h3>Looks like your search has no result...</h3>
        <div>Let alumni know what positions you are aiming for by posting them in the wish board.</div>
        <button @click="toWishlist">Go to Wish Board</button>
      </div>
    </div> -->

    <div v-if="filteredWishesData.length > 0">
      <WishCard
        v-for="wish in filteredWishesData"
        :key="wish.id"
        :companyId="wish.companyId"
        :companyName="wish.company"
        :isForHome="true"
        :wish="wish"
        @exitEdit="toggleEdit"
        @exitManage="handleReload"
        @change-target-chatter="changeTargetChatter"
      />
    </div>
  </div>
</template>

<script>
import getMyWishes from "@/composables/Profile/getMyWishes";
import getWish from "@/composables/WishList/getWish";
import getUser from "@/composables/getUser";
import firebase from "firebase/app";
import "firebase/firestore";

import {useRouter} from "vue-router";
import {onMounted, ref, watchEffect} from "vue";
import WishCard from "@/components/WishList/wishCard.vue";

export default {
  components: {WishCard},
  emits: ["homeWishLoaded", "change-target-chatter"],
  async setup(props, {emit}) {
    const router = useRouter();
    const {user} = getUser();
    const search = ref(null);
    const noSpecial = ref(null);
    noSpecial.value = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/;
    const myWishes = ref(null);
    const wishesData = ref([]);
    const filteredWishesData = ref([]);
    // const statusCounter = ref([0, 0, 0, 0]);
    const isLoaded = ref(false);
    const isSearching = ref(false);

    onMounted(async () => {
      await getMyWishes(myWishes, user.value.uid, false);
      let now = new firebase.firestore.Timestamp.now();

      for (let i = 0; i < myWishes.value.length; i++) {
        const tempWish = ref(null);
        let tempIsDefaultResume = null;
        // const tempProfile = ref(null);
        await getWish(tempWish, myWishes.value[i].companyId, myWishes.value[i].wishId);
        tempWish.value.candidates.forEach(candidate => {
          if (candidate.uid === user.value.uid){
            tempIsDefaultResume = candidate.isDefaultResume;
          }
        })

        wishesData.value.push({
          ...tempWish.value,
          isMine: true,
          isDefaultResume: tempIsDefaultResume,
          isExpired: -(tempWish.value.lastActivityTime.seconds - now.seconds) > 60 * 60 * 24 * 90,
          isSoonToExpire: (-(tempWish.value.lastActivityTime.seconds - now.seconds) > 60 * 60 * 24 * 60) && (-(tempWish.value.lastActivityTime.seconds - now.seconds) < 60 * 60 * 24 * 90),
          myWishId: myWishes.value[i].wishId,
          status: -(tempWish.value.lastActivityTime.seconds-now.seconds) > 60*60*24*90 ? "expired" : myWishes.value[i].status,
          displayTime: modifyDate(myWishes.value[i].time.toDate()),
          lastActivity: modifyDate(tempWish.value.lastActivityTime.toDate()),
          messageTargetId: myWishes.value[i].alumniId
        });

        // wishesData.value.forEach(wish => {
        //   if (-(wish.lastActivityTime.seconds-now.seconds) > 60*60*24*90){
        //     // console.log(wish.position + " Expired.");
        //     wish.isExpired = true;
        //   } else if (-(wish.lastActivityTime.seconds-now.seconds) > 60*60*24*60){
        //     wish.isSoonToExpire = true;
        //   }
        // })
      }
      isLoaded.value = true;
      emit("homeWishLoaded")

      let counter = 0;

      if (wishesData.value.length <= 3) {
        filteredWishesData.value = wishesData.value;
      } else {
        filteredWishesData.value = wishesData.value.filter(wish => {
          if (counter < 3) {
            return true;
          } else {
            return false;
          }
          counter++;
        })
      }
    });

    const modifyDate = (dateHold) => {
      const now = new Date(Date.now())
      let result = String(dateHold.getHours()).padStart(2, '0') + ":" + String(dateHold.getMinutes()).padStart(2, '0')
      const diffTime = Math.abs(dateHold - now);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      let msgTime = [
        dateHold.getMonth() + 1,
        dateHold.getDate(),
        dateHold.getFullYear()
      ]
      let nowTime = [
        now.getMonth() + 1,
        now.getDate(),
        now.getFullYear()
      ]
      if (diffDays <= 1 && msgTime[1] == nowTime[1]) {
        result = "Today at " + result
      } else if (diffDays == 1 && msgTime[1] != nowTime[1]) {
        result = "yesterday " + result
      } else if (diffDays > 1 && diffDays <= 6) {
        let msgDay
        switch (diffDays) {
          case 0:
            msgDay = "Sunday"
            break;
          case 1:
            msgDay = "Monday"
            break;
          case 2:
            msgDay = "Tuesday"
            break;
          case 3:
            msgDay = "Wednesday"
            break;
          case 4:
            msgDay = "Thursday"
            break;
          case 5:
            msgDay = "Friday"
            break;
          case 6:
            msgDay = "Saturday"
            break;
        }
        result = msgDay + " " + result
      } else if (diffDays > 6) {
        result = String(msgTime[0]).padStart(2, '0') + "/" + String(msgTime[1]).padStart(2, '0') + "/" + msgTime[2] + " " + result
      }
      return result
    };

    const handleSeeAllMyWishes = () => {
      router.push({name: "Junior-MyWishesDetail"});
    }

    const toWishlist = () => {
      router.push({name: "Junior-WishList"});
    };

    const changeTargetChatter = (chatterId) => {
      emit("change-target-chatter", chatterId);
    }

    const toggleEdit = (isChildEdit) => {
      // alert("isChildEdit: ", isChildEdit);
      if (isChildEdit == false) {
        router.go();
      }
    }

    const handleReload = () => {
      router.go();
    }


    watchEffect(() => {
      filteredWishesData.value = wishesData.value;

      filteredWishesData.value = filteredWishesData.value.filter((wish) => {
        if (search.value == null) {
          return true;
        } else if (noSpecial.value.test(search.value)) {
          // console.log("no specials");
          return true;
        }
        return wish.position.toLowerCase().match(search.value.toLowerCase());
      });
    });

    return {
      isLoaded,
      myWishes,
      wishesData,
      search,
      filteredWishesData,
      handleSeeAllMyWishes,
      toWishlist,
      isSearching,
      toggleEdit,
      handleReload,
      changeTargetChatter
    };
  },
};
</script>

<style scoped>
.home-wish {
  animation: fadein 1s;
  margin-bottom: 5vh;
}

.wish-title {
  font-size: var(--header);
  padding: 1.5vh 0vh;
}

.wish-bar {
  display: flex;
  flex-wrap: wrap !important;
  width: 100%;
  background-color: var(--purple);
  color: white;
  text-align: center;
  padding-left: 3vh;
  padding-right: 1.5vh;
  transition: 0.3s ease-out;
}

.wish-bar:hover {
  cursor: pointer;
  background-color: var(--purple-l);
  transition-duration: 0.2s;
}

.wish-bar h2 {
  text-align: left;
  flex: 0 1 50%;
}

.wish-bar-filler {
  flex: 1 0 auto;
}

.wish-bar-see-all {
  display: flex;
  flex: 0 1 15%;
  align-items: center;
  text-align: right;
}

.wish-bar-see-all div {
  flex: 0 1 30%;
}

.wish-bar-see-all h3 {
  flex: 1 0 auto;
}

/* .wish-bar-list {
  height: 100%;
  display: flex;
  flex: 0 1 60%;
}

.wish-bar-button {
  height: 100%;
  display: inline-block;
  font-size: 110%;
  margin-right: 2vh;
  text-align: center;
}

.wish-bar-button h4 {
  margin-top: 1vh;
  font-size: var(--text);
  font-weight: 400;
} */

.default-wish-column {
  display: flex;
  margin-top: 20px;
  background-color: var(--purple-s);
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
}

.default-wish-column-container {
  height: 90%;
  border: 3px dashed #D0D0D0;
  box-sizing: border-box;
  border-radius: 20px;
  width: 95%;
}

.default-wish-column h3 {
  text-align: center;
  vertical-align: middle;
  font-size: var(--subheader);
  font-family: Open Sans;
  font-weight: normal;
  margin: 6vh 0 5vh 0;
}

.default-wish-column div {
  text-align: center;
  vertical-align: middle;
  font-size: var(--text);
  font-weight: normal;
  margin: 2vh 0 2vh 0;
}

.default-wish-column button {
  margin-bottom: 4vh;
  text-align: center;
  vertical-align: middle;
  padding: 2vh 3vh;
  color: white;
  font-size: var(--text);
  background-color: var(--purple-l);
  border-radius: 10px;
  border: none;
  transition: 0.3s ease-out;
}

.default-wish-column button:hover {
  background-color: var(--purple);
  cursor: pointer;
}

.wish-search-bar {
  background: #FFFFFF;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  border-radius: 10px;
  min-width: 12rem;
  padding: 0 1vh;
  width: 40%;
  position: relative;
  top: -45px;
}

.wish-search-bar div {
  flex: 0 1 10%;
  background: #FFFFFF;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 80%;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.wish-search-bar input,
.wish-search-bar input:focus {
  display: block;
  flex: 0 0 80%;
  height: 2vh;
  background: #FFFFFF;
  border: none;
  outline: none;
}

@media screen and (max-width: 950px) {
  .search-bar {
    position: static;
  }

  .wish-search-bar {
    margin-top: 20px;
    width: 90%;
  }

  .wish-bar-see-all {
    flex: none;
  }

  .wish-bar {
    padding-left: 3vh;
  }

  .wish-bar-list {
    flex-wrap: wrap;
  }
}
</style>
